import React from 'react';
import Modal from 'react-modal';
import ModalProps from '../../models/modalProps';

Modal.setAppElement('#root');

function MyModal({isOpen, onRequestClose, children}: ModalProps) {

    return (
        <Modal
            isOpen={isOpen}
            style={{
                content: {
                    padding: '20px'
                }
            }}
            onRequestClose={onRequestClose}
        >
            {children}
        </Modal>
    );
}

export default MyModal;