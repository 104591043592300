import React from 'react';
import "./Info.css";
import {rightMouseBlocker} from "../../utils/helpers/utils";
import Header from "../../components/headerComponent/HeaderComponent";
import Footer from "../../components/footerComponent/FooterComponent";

function Info() {

    return (
        <div id={"info-container"} onContextMenu={rightMouseBlocker}>
            <Header/>
            <main>
                <h2>Informatie over Fotoclub ’t Gooi</h2>
                <h4>Algemeen</h4>
                <p>
                    Fotoclub ’t Gooi is opgericht in 1924 en heeft ± 20 enthousiaste leden.
                    Amateur-fotografen die in clubverband elkaar stimuleren tot het maken van betere foto’s. De leden
                    komen
                    eens in de 14 dagen, op woensdagavond bijeen in Buurthuis Lopes Dias, <br/>Lopes Diaslaan 213,
                    1222VE
                    Hilversum
                </p>

                <h4>Bespreken van foto's</h4>
                <p>
                    De fotobespreking omvat alle vormen van fotografische uitbeelding. Dat zijn Digitale fotos en
                    vergrotingen
                    in kleur en zwart wit.
                    Al deze opnames worden getoond en van opbouwend commentaar voorzien. Er wordt gekeken naar de
                    compositie,
                    inhoud, belichting en de manier waarop het onderwerp in beeld is gebracht. De leden tonen niet
                    alleen de
                    bekende genres als landschap-, mensen-, architectuur-, reis-, en portretfotografie maar ook bewerkte
                    foto’s
                    en abstracte beelden.
                    Voor de digitale bestanden gebruiken we een groot formaat TV scherm.
                </p>
                <h4>Andere activiteiten op de clubavonden</h4>
                <p>
                    Werk van anderen is leerzaam voor fotografen. Daarvoor nodigen we sprekers uit die een bepaald
                    onderwerp
                    behandelen. We organiseren uitwisselingen met andere fotoclubs en kijken naar fotocollecties.
                    Ook bespreken we werk wat leden willen inzenden bij externe wedstrijden. Tijdens besprekingen wordt
                    ook
                    technische informatie verstrekt. Als blijkt dat bepaalde kennis voor meerdere leden interessant is
                    wordt
                    er
                    een speciale avond georganiseerd waarbij dieper wordt ingegaan op deze materie.
                    Het informatieve nieuwsbulletin: “De Reflector” houdt je op de hoogte.
                </p>
                <h4>Exposities</h4>
                <p>
                    Enige keren per jaar richten we een tentoonstelling in met eigen werk. Deze exposities zijn vaak
                    thematisch
                    of naar aanleiding van een clubtocht of andere activiteit. Het is heel stimulerend om je eigen werk
                    tentoon
                    te stellen
                </p>
                <h4>Foto–excursie</h4>
                <p>

                    Met een aantal leden gaan we naar een van tevoren uitgekozen locatie waar elke fotograaf in zijn
                    eigen
                    stijl
                    foto’s maakt. Het bespreken van de resultaten naderhand tijdens de clubavond, is verassend en
                    leerzaam.
                </p>
                <h4>Fotobond / Afdeling Utrecht / ‘t Gooi</h4>
                <p>

                    We zijn aangesloten bij de Fotobond, afdeling “Utrecht/'t Gooi”. Door de afdeling en de Fotobond
                    worden
                    activiteiten georganiseerd. De fotoclub stimuleert het meedoen en het bezoeken van deze
                    activiteiten.
                    Als lid ontvang je ook gratis het landelijke fotoblad of de digitale versie “Fotobond in beeld”.
                    <br/>
                    Zie ook: <a href={"https://www.fotobond.nl"}
                                target={"_blank"}
                                rel={"noreferrer noopener"}
                >www.fotobond.nl
                </a> en <a href={"https://fotobond.nl/afdelingen/afdeling-07-utrecht-t-gooi"}
                           target={"_blank"}
                           rel={"noreferrer noopener"}
                >https://fotobond.nl/afdelingen/afdeling-07-utrecht-t-gooi</a>
                </p>
            </main>
            <Footer/>

        </div>
    );
}

export default Info;