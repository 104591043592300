import React from 'react';
import {auth} from "../../../config/firebase";
import {useNavigate} from "react-router-dom";
import {AdminMenuItems} from "../../../utils/CONSTANTS";

interface AdminMenuProps {
    setSelected: (id: string) => void;
}

function AdminMenu({setSelected}: Readonly<AdminMenuProps>) {
    const navigate = useNavigate()
    return (
        <div className={"choice-buttons-container"}>
            {(Object.keys(AdminMenuItems) as Array<keyof typeof AdminMenuItems>).map((key) => {
                return (
                    <button type="button"
                            key={AdminMenuItems[key]}
                            className={'btn'}
                            onClick={() => setSelected(AdminMenuItems[key])}>{AdminMenuItems[key]}
                    </button>
                );
            })
            }
            <button type="button" className={'btn'}
                    onClick={() => auth.signOut().then(() => navigate("/"))}>Uitloggen
            </button>

        </div>
    );
}

export default AdminMenu;