import React, {useState} from 'react';
import {FiEye, FiEyeOff} from "react-icons/fi";
import IEvent from "../../../models/eventItem";
import EventService from "../../../services/EventService";
import "./EventItem.css";
import {CiMaximize1, CiMinimize1} from "react-icons/ci";
import {isValidDate} from "../../../utils/helpers/validators";
import {FaRegTrashAlt} from "react-icons/fa";
import {Alerts, Confirms, Prompts} from "../../../utils/CONSTANTS";
import {MdOutlineDescription, MdOutlineLink} from "react-icons/md";

interface EventProps {
    eventInput: IEvent;
    delete: (id: string) => void;
}

function Event({eventInput, delete: onDelete}: Readonly<EventProps>) {
    const [toggleInfo, setToggleInfo] = React.useState<boolean>(false);
    const [itemEventData, setItemEventData] = React.useState<IEvent>(eventInput);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [editMode, setEditMode] = React.useState<boolean>(false);

    const handleMouseEnter = (index: any) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    function handleDeletePhotoFromEvent(id: string, index: number) {
        if (window.confirm(Confirms.DeletePicture)) {
            EventService
                .update(id, "photos", itemEventData.photos.filter((_, i) => i !== index))
                .then(() => setItemEventData((prevEvent) => ({
                    ...prevEvent,
                    photos: prevEvent.photos.filter((_, i) => i !== index),
                })))
                .catch(e => console.error(e))
        }
        setHoveredIndex(null);
    }

    function changeVisibility(id: string) {
        let changeVisibility = window.confirm(Confirms.ChangeVisibility)
        if (changeVisibility) {
            EventService
                .update(id, "visible", !itemEventData.visible)
                .then(() => setItemEventData({...itemEventData, visible: !itemEventData.visible}))
                .catch(e => console.error(e))
        }
    }

    function changeTitle(id: string) {
        let newTitle = prompt(Prompts.NewTitle, itemEventData.title)
        if (!newTitle) return
        EventService
            .update(id, "title", newTitle)
            .then(() => setItemEventData({...itemEventData, title: newTitle}))
            .catch(e => console.error(e))
    }

    function changeBeschrijving(id: string) {
        EventService
            .update(id, "beschrijving", itemEventData.beschrijving)
            .then(() => setEditMode(!editMode))
            .catch(e => console.error(e))
    }

    function changeLocation(id: string) {
        let newLocation = prompt(Prompts.NewLocation, itemEventData.locatie)
        EventService
            .update(id, "locatie", newLocation)
            .then(() => setItemEventData({...itemEventData, locatie: newLocation}))
            .catch(e => console.error(e))
    }

    function changeDatum(id: string) {
        let newDatum = prompt(Prompts.NewDate, itemEventData.datum)
        if (newDatum && !isValidDate(newDatum)) {
            alert(Alerts.WrongDateFormat)
            return
        }
        if (!newDatum) return
        EventService
            .update(id, "datum", newDatum)
            .then(() => setItemEventData({...itemEventData, datum: newDatum}))
            .catch(e => console.error(e))
    }

    function changeSoort(id: string) {
        let newSoort = prompt(Prompts.NewSoort, itemEventData.soort)
        if (!newSoort) return
        EventService
            .update(id, "soort", newSoort)
            .then(() => setItemEventData({...itemEventData, soort: newSoort}))
            .catch(e => console.error(e))
    }

    function changeAltForPicture(id: string, index: number) {
        let newAlt = prompt(Prompts.NewAlt, itemEventData.photos[index].alt)
        if (!newAlt) return
        EventService
            .update(id, "photos", itemEventData.photos.map((photo, i) => {
                if (i === index) {
                    return {...photo, alt: newAlt}
                } else {
                    return photo
                }
            }))
            .then(() => setItemEventData((prevEvent) => ({
                ...prevEvent,
                photos: prevEvent.photos.map((photo, i) => {
                    if (i === index) {
                        return {...photo, alt: newAlt}
                    } else {
                        return photo
                    }
                })
            })))
            .catch(e => console.error(e))
    }

    function changeLinkForPicture(id: string, index: number) {
        let newLink = prompt(Prompts.NewImgUrl, itemEventData.photos[index].link)
        if (!newLink) return
        if (newLink) {
            EventService
                .update(id, "photos", itemEventData.photos.map((photo, i) => {
                    if (i === index) {
                        return {...photo, link: newLink}
                    } else {
                        return photo
                    }
                }))
                .then(() => setItemEventData((prevEvent) => ({
                    ...prevEvent,
                    photos: prevEvent.photos.map((photo, i) => {
                        if (i === index) {
                            return {...photo, link: newLink}
                        } else {
                            return photo
                        }
                    })
                })))
                .catch(e => console.error(e))
        }
    }

    function addNewPhoto(id: string) {
        let newImgUrl = prompt(Prompts.NewImgUrl)
        let newAlt = prompt(Prompts.NewAlt)
        if (!newImgUrl || !newAlt) return
        if (newImgUrl) {
            EventService
                .update(id, "photos", [...itemEventData.photos, {link: newImgUrl, alt: newAlt}])
                .then(() => setItemEventData({
                    ...itemEventData,
                    photos: [...itemEventData.photos, {link: newImgUrl, alt: newAlt}]
                }))
                .catch(e => console.error(e))
        }
    }

    return (
        <div className={"event-wrapper"}>
            <div className={"event-top"}>
                <div className={"event-top-left"}>
                    <h1 style={{marginLeft: 10, cursor: "pointer"}}
                        onClick={() => changeVisibility(itemEventData.id)}>{itemEventData.visible ?
                        <FiEye/> :
                        <FiEyeOff/>}
                    </h1>
                </div>
                <div className={"event-top-middle-left"}>
                    <h1 style={{cursor: "pointer"}}
                        onClick={() => changeTitle(itemEventData.id)}>{itemEventData.title}</h1>
                </div>
                <div className={"event-top-middle-container"}>
                    <div className={"event-top-middle-right"}>
                        <p style={{cursor: "pointer"}}
                           onClick={() => changeLocation(itemEventData.id)}>Locatie: {itemEventData.locatie ? itemEventData.locatie : `[Geen locatiegegevens beschikbaar]`} </p>
                        <p style={{cursor: "pointer"}}
                           onClick={() => changeDatum(itemEventData.id)}>Eventdatum: {itemEventData.datum}</p>
                    </div>
                    <div className={"event-top-middle-right"}><h1
                        style={{marginRight: 10, cursor: "pointer", alignSelf: ""}}
                        onClick={() => setToggleInfo(!toggleInfo)}>{toggleInfo ? <CiMinimize1/> :
                        <CiMaximize1/>}</h1>
                    </div>
                </div>

            </div>
            {toggleInfo &&
                <div className={"eventItem-middle"}>
                    <p>Beschrijving:</p>
                    {!editMode && <p style={{cursor: "pointer"}}
                       onClick={() => setEditMode(!editMode)}>{itemEventData.beschrijving}</p>}
                    {editMode &&
                        <>
                            <textarea placeholder={itemEventData.beschrijving} wrap={"  hard"} value={itemEventData.beschrijving}
                                      onChange={(e) => setItemEventData({
                                          ...itemEventData,
                                          beschrijving: e.target.value
                                      })}/>
                            <button className={'btn'} onClick={() => changeBeschrijving(itemEventData.id)}>Opslaan
                            </button>
                        </>
                    }
                    <p style={{cursor: "pointer"}} onClick={() => changeSoort(itemEventData.id)}>Soort: {itemEventData.soort}</p>
                    <p>Foto's:</p>
                    <div id={"foto-overzicht"}>
                        
                        {itemEventData?.photos?.map(({link, alt}, index) => {
                            return (
                                <div className={'foto-container-eventItem'}
                                     id={`${index}`}
                                     key={index}
                                     onMouseEnter={() => handleMouseEnter(index)}
                                     onMouseLeave={handleMouseLeave}>
                                    <img src={link}
                                         alt={alt}
                                         className={"foto"}/>
                                    {hoveredIndex === index && (
                                        <>
                                    <span className={'buttons'}>
                                        <button
                                            onClick={() => changeAltForPicture(eventInput.id, index)}><MdOutlineDescription/></button>
                                        <button
                                            onClick={() => changeLinkForPicture(eventInput.id, index)}><MdOutlineLink/></button>
                                        </span>
                                            <button className={'delete-button'}
                                                    onClick={() => handleDeletePhotoFromEvent(itemEventData.id, index)}>
                                                <FaRegTrashAlt/></button>
                                        </>
                                    )}
                                </div>);
                        })}
                        <div className={'foto-container'}>
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9e/Plus_symbol.svg/640px-Plus_symbol.svg.png"
                                onClick={() => addNewPhoto(itemEventData.id)}
                                className={"foto-add"} alt={""}/>

                        </div>
                    </div>
                    <div className={"event-bottom"}>
                        <p>Aangemaakt op: {itemEventData.createdAt}</p>
                        <p>Aangemaakt door: {itemEventData.createdBy}</p>
                        <p style={{cursor: "pointer"}} onClick={() => onDelete(itemEventData.id)}><FaRegTrashAlt/>
                        </p>
                    </div>
                </div>
            }
        </div>
    )
        ;
}

export default Event;