import React, {useEffect, useState} from 'react';
import CalendarService from "../../../services/CalendarService";
import IKalenderItem from "../../../models/kalenderItem";
import {FaEdit, FaRegTrashAlt} from "react-icons/fa";
import "./KalenderAdmin.css";
import {isValidDate} from "../../../utils/helpers/validators";
import {Alerts, Confirms} from "../../../utils/CONSTANTS";
import {sortAgendaItems} from "../../../utils/helpers/sorters";
import {handleChangeOfState} from "../../../utils/helpers/test";

function KalenderAdmin() {
    const [kalenderItems, setKalenderItems] = useState<Array<IKalenderItem>>([])
    const [newItem, setNewItem] = useState<IKalenderItem>({
        id: "",
        datum: "",
        tijd: "",
        soort: "",
        programma: "",
        geweest: false,
        bijzonderheden: "",
        link: false,
    });
    const formFilled = (newItem.datum !== "") && (newItem.soort !== "") && (newItem.programma !== "")

    function getCurrentItems() {
        CalendarService
            .getAll()
            .then((res) => setKalenderItems(res))
            .catch(e => console.error(e))
    }

    useEffect(() => {
        getCurrentItems();
    }, []);


    function addItem() {
        if (isValidDate(newItem.datum)) {
            CalendarService
                .create(newItem)
                .then((res) => setKalenderItems(
                    [...kalenderItems, {
                        ...newItem,
                        id: res.id,
                        createdAt: res.createdAt,
                        createdBy: res.createdBy
                    }]))
                .catch(e => console.error(e))
                .finally(() => resetForm());
        } else {
            alert(Alerts.WrongDateFormat);
        }
    }

    function handleDelete(id: string) {
        if (window.confirm(Confirms.DeleteAgendaItem)) {
            CalendarService
                .deleteCalendarItem(id)
                .then(() => setKalenderItems(kalenderItems.filter((item) => item.id !== id)))
                .catch(e => console.error(e))
                .finally(() => resetForm())
        }
    }

    function selectItemToEdit(id: string) {
        CalendarService
            .getOne(id)
            .then((res) => {
                console.log(res.data())
                setNewItem({
                    id: res.id,
                    programma: res.data().programma,
                    bijzonderheden: res.data().bijzonderheden,
                    datum: res.data().datum,
                    tijd: res.data().tijd,
                    soort: res.data().soort,
                    createdAt: res.data().createdAt,
                    createdBy: res.data().createdBy,
                    geweest: res.data().geweest,
                    link: res.data().link
                })
            })
            .catch(e => console.error(e))
    }

    function resetForm() {
        setNewItem({
            id: "",
            datum: "",
            tijd: "",
            soort: "",
            programma: "",
            geweest: false,
            bijzonderheden: "",
            link: false,
        })
    }

    function updateItem(id: string) {
        CalendarService
            .updateItem(id, newItem)
            .then(() => setKalenderItems(kalenderItems.map((item) => item.id === id ? newItem : item)))
            .catch(e => console.error(e))
            .finally(() => resetForm())
    }

    return (
        <div className="calendar-admin-container">
            <button type="button" value="Kalender bijwerken" onClick={getCurrentItems}>Items ophalen</button>
            <h1>Kalender</h1>
            <table className="calendar-items-overview">
                <tbody>
                <tr>
                    <th>Soort</th>
                    <th>Datum</th>
                    <th>Tijd</th>
                    <th>Programma</th>
                    <th>Bijzonderheden</th>
                    <th>Link</th>
                    <th>Actie</th>
                </tr>
                {kalenderItems && sortAgendaItems(kalenderItems).map((item: IKalenderItem) => (
                    <tr key={item.id}>
                        <td>{item.soort}</td>
                        <td>{item.datum}</td>
                        <td>{item.tijd}</td>
                        <td>{item.programma}</td>
                        <td>{item.bijzonderheden}</td>
                        <td><input id={item.id} type={"checkbox"} checked={item.link} readOnly={true}/></td>
                        <td>
                            <button type={"button"} onClick={() => selectItemToEdit(String(item.id))}><FaEdit/>
                            </button>
                            <button type={"button"} onClick={() => handleDelete(String(item.id))}><FaRegTrashAlt/>
                            </button>
                        </td>
                    </tr>
                ))}

                <tr>
                    <td>
                        <select name="soort"
                                value={newItem.soort}
                                onChange={(e) => handleChangeOfState(e, setNewItem)}>
                            <option style={{display: "none"}}>Soort</option>
                            <option value="Clubtocht">Clubtocht</option>
                            <option value="Clubavond">Clubavond</option>
                            <option value="Workshop">Workshop</option>
                            <option value="Activiteit">Activiteit</option>
                            <option value="Expositie">Expositie</option>
                        </select>
                    </td>
                    <td>
                        <input type={"text"} value={newItem.datum}
                               name="datum"
                               placeholder={"Voer de datum in als DD-MM-YYYY"}
                               onChange={(e) => handleChangeOfState(e, setNewItem)}/>
                    </td>
                    <td>
                        <input type={"text"}
                               value={newItem.tijd}
                               name="tijd"
                               placeholder={"Tussen welke tijden vind het plaats"}
                               onChange={(e) => handleChangeOfState(e, setNewItem)}/>
                    </td>
                    <td>
                        <input type={"text"}
                               value={newItem.programma}
                               name="programma"
                               placeholder={"Wat staat er op het programma?"}
                               onChange={(e) => handleChangeOfState(e, setNewItem)}/>
                    </td>
                    <td>
                        <input type={"text"}
                               value={newItem.bijzonderheden}
                               name="bijzonderheden"
                               placeholder={"Zijn er bijzonderheden?"}
                               onChange={(e) => handleChangeOfState(e, setNewItem)}/>
                    </td>
                    <td>
                        <input type={"checkbox"}
                               checked={newItem.link}
                               name="link"
                               onChange={(e) => handleChangeOfState(e, setNewItem)}/>
                    </td>
                    <td>
                        {newItem.id === "" ?
                            <button disabled={!formFilled} type={"button"} onClick={addItem}>Toevoegen</button>
                            :
                            <button disabled={!formFilled} type={"button"}
                                    onClick={() => updateItem(String(newItem.id))}>Wijzigen</button>
                        }
                        <button disabled={!formFilled} type={"button"}
                                onClick={resetForm}>{newItem.id ? `Annuleer` : `Reset`}</button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default KalenderAdmin;